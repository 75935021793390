import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext";
import './blog_details.css';

export const SpecificBlogCategoryMain = (props) => {
    let {blog} = props
    const { blogCategory, baseBackendRoute,blogsList } = useContext(AppContext);
    const [category,setCategory] = useState()
    console.log("kjisjeij",blogCategory,blogsList,category,blog)

    useEffect(() => {
        if(blogsList){
            let cat = blogsList?.blog_category?.find((e) => e?.slug == blog)
            console.log("nnoiri",cat,blogsList?.blog_category)
            setCategory(cat)
        }
        // Your effect logic here
    }, [blogCategory]);

    return (
        <>
            <main id="primary" className="site-main">
                <div id="bcn_widget-2" className="widget_breadcrumb_navxt">
                    <div className="breadcrumbs" vocab="https://schema.org/" typeof="BreadcrumbList">
                        <span property="itemListElement" typeof="ListItem">
                            <a property="item" typeof="WebPage" title="Go to Herald Blog." href="/blogs" className="home">
                                <span property="name">Home</span>
                            </a>
                            <meta property="position" content={1} />
                        </span>
                        <img width={504} height={504} src="https://www.herold.at/blog/wp-content/uploads/2021/04/slide_right.svg" />
                        <span property="itemListElement" typeof="ListItem">
                            <span property="name" className="archive taxonomy category current-item">
                                {category?.category_name}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <h1 className="cols-6" style={{    padding: "0 0 0 3%",color: "black"}}>
                        {category?.category_name}
                    </h1>
                    <div className="flex_wrapper wide_flex_wrapper">
                        <div
                            className="wp-block-cover has-background-dim herold_header_single header_archive"
                            style={{ backgroundImage: `url(${baseBackendRoute}${category?.image})`,flex: "0 0 100%" }}
                        >
                            {/* Blog details list
                            <div className="fullwidth_inner">
                                {blogCategory?.blogs.map((blog, index) => (
                                    <div key={index} className="blog-category-card-details card">
                                        <h2>{blog.title}</h2>
                                        <p>{blog.name}</p>
                                        <p>{blog.slug}</p>
                                        <p>{blog.image_title}</p>
                                        <p>{blog.description}</p>
                                        <p>{blog.duration}</p>
                                        <div className="image-container">
                                            <img src={`${baseBackendRoute}/${blog.image}`} alt={blog.title} className="category-image" />
                                        </div>
                                        <a href={`/specific-blog/${blog.slug}`} className="cta_btn yellow">Show more</a>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>

                    {/* Button */}
                    {/* <div className="button-wrapper">
                        <a href="/some-route" className="cta_btn blue btn">Click Me</a>
                    </div> */}

                    {/* Category details list */}
                    {/* <div className="flex_wrapper wide_flex_wrapper">
                        <div className="fullwidth_inner">
                                <div className="blog-category-card-details card">
                                    <p>{category?.category_name}</p>
                                    <div className="image-container">
                                        <img src={`${baseBackendRoute}/${category?.image}`} alt={category?.title} className="category-image" />
                                    </div>
                                </div>
                        </div>
                    </div> */}

                    <div className="archive_desc flex_wrapper">
                        <p>
                            <strong>{category?.title}</strong>
                        </p>
                    </div>
                    
                    <div className="flex_wrapper three_posts_wrap">
                        <ul className="current_posts three_posts" style={{flex : "0 0 100%"}}>
                            {blogCategory?.blogs?.map((data, index) => (
                                              <li className="shadow">

                                              <div className="sh_inner">

                                                  <div className="sh_img_inner">
                                                      <a href={`/specific-blog/${data.slug}`}>
                                                          <img
                                                              width={400}
                                                              height={267}
                                                              src={`${baseBackendRoute}${data.image}`}
                                                              className="attachment-blurred_header size-blurred_header wp-post-image"
                                                              alt="Doener Vienna"
                                                              decoding="async"
                                                              srcSet={`${baseBackendRoute}${data.image}`}
                                                              sizes="(max-width: 400px) 100vw, 400px"
                                                              _mstalt={152464}
                                                              _msthash={147}
                                                          />
                                                      </a>
                                                  </div>
                                                  <div className="shadow_inner">

                                                      <p
                                                          className="right_author_name"
                                                          _msttexthash={884728}
                                                          _msthash={148}
                                                      >
                                                          {data.name}
                                                      </p>
                                                      <p
                                                          className="author_readtime"
                                                          _msttexthash={351741}
                                                          _msthash={149}
                                                      >
                                                          {data?.editor?.editor_name} | {data.duration}.
                                                      </p>
                                                      <a
                                                          href={`/specific-blog/${data.slug}`}
                                                          className="cta_btn blue"
                                                          _msttexthash={204802}
                                                          _msthash={150}
                                                      >
                                                          To the article
                                                      </a>
                                                  </div>
                                              </div>
                                          </li>
                            ))}
                        </ul>
                        <a href="/blogs" className="cta_btn blue btn loadMoreBtn">Back to overview</a>
                    </div>
                </div>
            </main>
        </>
    );
};
